import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import BlogEntry from '../models/BlogEntry'
import Entity from '../models/Entity'
import { getHeaders } from './auth'

export const getBlogEntries = async (): Promise<ArrayPayload<BlogEntry>> => {
	const response = await fetch(
		`${API_URI}/blog-entries` +
			`?populate=logo` +
			`&sort[0]=priority:desc` +
			`&sort[1]=publishedAt:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getBlogEntry = async (
	urlPath: string
): Promise<Entity<BlogEntry>> => {
	const response = await fetch(
		`${API_URI}/blog-entries?populate=logo&filters[urlPath][$eq]=${urlPath}`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}
