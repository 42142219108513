import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import { getBlogEntry } from '../../apiClients/blog-entries'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromBlogEntry,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import BlogEntry from '../../models/BlogEntry'
import Breadcrumb from '../../models/Breadcrumb'
import Entity from '../../models/Entity'
import { SettingKey } from '../../models/GlobalSetting'
import { buildMetaTitle } from '../../utils/meta'

export default function BlogDetails({ params, location }: PageProps) {
	const [entry, setEntry] = useState<Entity<BlogEntry>>()
	const [loading, setLoading] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getBlogEntry(params.urlPath)
		if (response) setEntry(response)
		setLoading(false)
	}, [params.urlPath])

	const breadcrumbs: Breadcrumb[] = [
		{ link: '/blog/', featureName: 'blog' },
		{
			link: location.pathname,
			display: entry?.attributes.title ?? params.urlPath,
		},
	]

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([entry?.attributes.title, metaTitle])}</title>
			</Helmet>
			<LayoutContainer>
				{loading && !entry ? (
					<Spinner />
				) : entry ? (
					<>
						<MarkdownRenderer
							header={
								<StoreItemHeader {...propsFromBlogEntry(entry.attributes)} />
							}
							children={
								entry?.attributes?.markdown ?? '# Blog entry not available'
							}
						/>
					</>
				) : (
					<Heading>Blog entry not found</Heading>
				)}
			</LayoutContainer>
		</Layout>
	)
}
